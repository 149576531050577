@import './color.scss';
@import './custom.scss';

html {
  font-size: 14px;
}

body {
  overflow-y: hidden !important;
  max-height: 100vh !important;
  background-color: black;
}

tr.selected-coupon td {
  background-color: #f0f8ff;
  color: $dark;
}

.tile:hover {
  opacity: 0.8 !important;
}

.image-order {
  background-color: #656567;
  display: inline-block;
  padding: 5px;
  border-radius: 0.25rem;
  color: white;
  text-align: center;
}

.spacing {
  margin: 5px;
}

.no-background-btn {
  background-color: transparent !important;
  border: none !important;
  color: inherit !important;
  box-shadow: none !important;
  padding: 0 !important;
  cursor: pointer;
}

.no-background-btn:hover,
.no-background-btn:focus,
.no-background-btn:active,
.no-background-btn.btn-primary:hover,
.no-background-btn.btn-primary:focus,
.no-background-btn.btn-primary:active {
  background-color: transparent !important;
  border: none !important;
  color: inherit !important;
  box-shadow: none !important;
}

.product-image {
  width: 200px;
  height: 300px;
}

.video-thumbnail {
  width: 300px;
  height: 400px;
}

.product-image:hover,
.video-thumbnail:hover {
  opacity: 0.8 !important;
}

svg {
  pointer-events: none;
} // so react-icon SVG files can be added to buttons and clicked

.manage-banners-tiles-container {
  display: flex; /* Use flexbox to align items */
  justify-content: space-between; /* Space items evenly */
}

.rw-order-status {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.rw-order-status-picked {
  display: inline-block;
  height: 20px;
  width: 20px;
  font-size: 20px;
  color: #6c8f34; /* Adjust as needed */
}

.rw-order-tile.rw-order-tile-selected {
  border: 2px solid #007bff;
}

.rw-order-status-green {
  background-color: #5a8318;
}

.rw-order-status-yellow {
  background-color: #efcb19;
}

.rw-order-status-red {
  background-color: #9c4335;
}

.rw-order-status-gray {
  background-color: #444141;
}

.rw-order-status-void {
  background-color: #d3d3d3; /* Light gray background */
}

.rw-order-status-yellow-hold {
  background-color: #efcb19; /* Light yellow background */
}

.rw-order-status-void,
.rw-order-status-yellow-hold {
  font-weight: bold;
  text-align: center;
}

.rw-order-tile {
  padding: 10px;
  border: 1px solid #6f6f6f;
  display: block;
  cursor: pointer;
}

.product-status-dot {
  height: 14px;
  width: 14px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.product-status-dot-green {
  background-color: #6c8f34;
}
.product-status-dot-yellow {
  background-color: #e0c643;
}
.product-status-dot-red {
  background-color: #953324;
}
.product-status-dot-yellow-hold {
  background-color: #efcb19; /* Light yellow background */
  color: rgb(0, 0, 0); /* Change text color as needed */
  border-radius: 50%; /* Makes it a circle */
  width: 20px; /* Adjust the size as needed */
  height: 20px; /* Adjust the size as needed */
  display: flex; /* Use flex to center the content */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  font-weight: bold; /* Make the "V" stand out */
}

.spacer-under-header-nav-bar {
  width: 100%;
  @media (max-width: 992px) {
    height: 116px !important;
    background-color: $white !important;
  }
}

.spacer-under-header-nav-bar-dm {
  width: 100%;
  @media (max-width: 992px) {
    height: 116px !important;
    background-color: $dm-bg !important;
  }
}

.fixed-spacer-under-header-nav-bar {
  @media (max-width: 992px) {
    height: 50px !important;
    background-color: $white !important;
  }
}

.fixed-spacer-under-header-nav-bar-dm {
  @media (max-width: 992px) {
    height: 50px !important;
    background-color: $dm-bg !important;
  }
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensures children fill the entire height of the card */
}

.no-focus-dropdown-button {
  :focus {
    box-shadow: none !important;
  }
}

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: $white;
}

.pagination {
  padding: 0;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  text-align: center;
}

.pagination .page-item {
  display: inline-block;
  margin: 0 3px;
}

.pagination li {
  display: block;
}

.pagination .page-item .page-link {
  color: $primary;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: $tertiary;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  margin: 0;
}

.pagination .page-item.active .page-link {
  background-color: $primary;
  color: $white;
}

.pagination .enabled.page-item:hover .page-link {
  background-color: $primary;
  color: $white;
}

.pagination .button-disabled.page-item .page-link {
  background-color: $light;
  color: $white;
}

.dm-pagination.pagination .page-item .page-link {
  color: $dm-text;
  background: $dm-bg;
}

.dm-pagination.pagination .page-item.active .page-link {
  background-color: $dm-card-bg;
  color: $white;
}

.dm-pagination.pagination .enabled.page-item:hover .page-link {
  background-color: $dm-card-bg;
  color: $white;
}

.dm-pagination.pagination .button-disabled.page-item .page-link {
  background-color: $dm-bg;
  color: $light;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh !important;
  // max-width: 1500px;
  margin: 0 auto;
}

.body-size {
  flex: 1;
}

.w_10 {
  width: 10% !important;
}
.w_20 {
  width: 20% !important;
}
.w_50 {
  width: 50% !important;
}
.w_60 {
  width: 60% !important;
}
.w_75 {
  width: 75% !important;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.carousel-thumbnail {
  max-width: 40px;
  height: auto !important;
  max-height: 40px;
  margin-left: auto !important;
  margin-right: auto !important;
  cursor: pointer;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.button-radio {
  padding: 0;
  margin: 0;
  color: $text-muted;

  & input[type='radio'] {
    visibility: hidden;
    height: 0;
    width: 0;
    margin: 0;
  }
  & input[type='radio'] + label {
    margin: 0;
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    // border-color: $gray-400;
    border-radius: 5px;
    cursor: pointer;
  }
  & input[type='radio']:checked + label {
    background-color: $secondary;
    // border-color: $dark;
    color: white;
  }
  & input[type='radio']:hover + label {
    background-color: $secondary;
    // border-color: $dark;
    color: white;
  }
}

.excel-green {
  color: $excel-green !important;
}

.pdf-red {
  color: $pdf-red !important;
}

.input-quantity-control {
  min-height: 50px;
}

///*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}

.remove-right-border {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.react-datepicker__time-list-item--disabled {
  display: none !important;
} // from https://github.com/Hacker0x01/react-datepicker/issues/2056

.custom-date-picker-width {
  width: 100%;
  padding: 5px 5px 5px 10px;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 8px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  font-size: 16px;
}

.react-datepicker__input-container input:focus {
  border-color: #007bff; /* Adjust as needed */
  outline: none;
}

.react-datepicker {
  border: 1px solid lightgrey;
  border-radius: 4px;
}

.react-datepicker__header {
  background-color: #f1f1f1; /* Adjust as needed */
}

.react-datepicker__day--selected {
  background-color: #007bff; /* Adjust as needed */
  color: white;
}

.react-datepicker-popper {
  z-index: 999999999999 !important;
}

.dm-bg {
  background-color: $dm-bg !important;
}

.dm-body-bg {
  background-color: $dm-body-bg !important;
}

.dm-card-bg {
  background-color: $dm-card-bg !important;
}

.dm-text {
  color: $dm-text !important;
}

.dm-text-bg {
  color: $dm-bg !important;
}

.dm-body-text-bg {
  color: $dm-body-bg !important;
}

.dm-proline-primary-bg {
  background-color: $dm-proline-primary !important;
}

.dm-proline-primary-text {
  color: $dm-proline-primary !important;
}

.rw-fs-big-1 {
  font-size: 80px;
}

.rw-fs-big-2 {
  font-size: 70px;
}

.rw-fs-big-3 {
  font-size: 60px;
}

.dm-rw-carousel .carousel .carousel-control-prev-icon {
  background-color: black !important;
}

.dm-rw-carousel .carousel .carousel-control-next-icon {
  background-color: black !important;
}

.rw-main-content-div {
  height: 100vh;
  width: 100%;
  min-width: 100%;
}

.rw-main-content-inner-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.rw-main-content-upper-div {
  width: 100%;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.rw-main-content-lower-div {
  flex-shrink: 0;
  overflow: hidden;
}

.rw-orders-side-nav {
  display: flex;
  flex-direction: column;
  height: 123vh; /* Adjusted to full viewport height */
  overflow: hidden;
  transition: width 0.3s ease; /* Smooth transition for width */
}

.rw-orders-side-nav.open {
  width: 400px;
}

.rw-orders-side-nav.closed {
  width: 0px;
}

#orders-collapse {
  flex-grow: 1;
  overflow-y: auto; /* Allows vertical scrolling */
}

.rw-toggle-button {
  margin-bottom: 10px;
}

.rw-side-nav-div {
  height: 100vh;
  width: 220px;
}

.rw-side-nav-inner-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rw-side-nav-upper-div {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.rw-side-nav-lower-div {
  flex-shrink: 0;
  overflow: hidden;
}

.rw-side-nav .rw-side-nav-item:hover {
  background-color: #ecf2f9 !important;
  cursor: pointer;
}

.rw-side-nav-dm .rw-side-nav-item:hover {
  background-color: #394047 !important;
  cursor: pointer;
}

.rw-side-nav .rw-side-nav-has-active {
  background-color: #ecf2f9 !important;
}

.rw-side-nav-dm .rw-side-nav-has-active {
  background-color: #394047 !important;
}

.rw-side-nav .selected {
  background-color: #ecf2f9;
}

.rw-side-nav-dm .selected {
  background-color: #394047;
}

.rw-side-nav .ind-selected {
  width: 5px;
  background-color: $info;
}

.rw-side-nav .ind-unselected {
  width: 5px;
}

.rw-side-nav-dm .ind-selected {
  width: 5px;
  background-color: $light;
}

.rw-side-nav-dm .ind-unselected {
  width: 5px;
}

.rw-header-link:hover {
  background-color: $dm-card-bg;
}

.custom-control-label::before {
  background-color: #28a745;
}

.custom-switch .custom-control-label::after {
  background-color: white;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: red;
}

.rw-table th {
  background-color: $light !important;
}

.rw-table-dm th {
  background-color: $dm-card-bg !important;
}

.rw-nav-item:hover {
  background-color: $light;
}

.rw-nav-item-alt:hover {
  background-color: $dark !important;
  color: $light !important;
}

.rw-nav-item-dm:hover {
  background-color: $dm-card-bg;
}

.rw-nav-item-dm-alt:hover {
  background-color: $light !important;
  color: $dark !important;
}

.rw-custom-select:hover {
  cursor: pointer;
  background-color: $light;
}

.rw-custom-select-dm:hover {
  cursor: pointer;
  background-color: $dm-card-bg;
}

.rw-breadcrumb .breadcrumb {
  margin-bottom: 0;
}

.rw-breadcrumb-lm a {
  text-decoration: none !important;
  color: $dark !important;
}

.rw-breadcrumb-dm a {
  text-decoration: none !important;
  color: $light !important;
}

.rw-tooltip-no-arrow .tooltip-arrow {
  display: none !important;
}

tr.rw-task-alert-overdue td {
  background-color: $accent;
  color: $light;
  font-weight: bold;
}

tr.rw-task-alert-due-today td {
  background-color: $warning;
  color: $light;
}

tr.rw-task-alert-due-soon td {
  background-color: $light;
  color: $dark;
}

.rw-file-dropzone-overlay {
  position: relative; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 0; /* Specify a stack order in case you're using a different order for other elements */
}

input[type='file'].rw-file-dropzone-input {
  color: rgba(0, 0, 0, 0);
}

.rw-file-upload-modal .modal-dialog {
  width: 700px;
  max-width: 100vw !important;
}

.rw-change-password-modal .modal-dialog {
  width: 800px;
  max-width: 100vw !important;
}

.rw-audit-log-modal .modal-dialog {
  width: 1200px;
  max-width: 95vw !important;
}

.rw-audit-log-modal-body {
  height: 80vh;
  max-height: 80vh;
  overflow: auto;
}

.rw-display-field-box {
  background-color: #e9ecef;
  min-height: 37px;
}

.rw-display-field-box-dm {
  background-color: #343a40;
  min-height: 37px;
}

tr.total-row td {
  background-color: #eeeeee; /* Light grey background */
  font-weight: bold;
  text-align: right; /* Optional: Align text to the right */
}
tr.total-row-dm td {
  background-color: #383838; /* Light grey background */
  font-weight: bold;
  text-align: right; /* Optional: Align text to the right */
}

tr.even-row td {
  background-color: #ffffff;
}
tr.odd-row td {
  background-color: #fdfdfd;
}

tr.even-row-dm td {
  background-color: #000000;
}
tr.odd-row-dm td {
  background-color: #2a2a2a;
}

tr.pink td {
  background-color: #fbc8c8;
}
tr.pink-dm td {
  background-color: #9c7e7e;
}

tr.white td {
  background-color: #ffffff;
}
tr.white-dm td {
  background-color: #000000;
}

tr.light-green td {
  background-color: #e2f6c6;
}
tr.light-green-dm td {
  background-color: #4d642c;
}

tr.grey td {
  background-color: #c9cbc6;
}
tr.grey-dm td {
  background-color: #6e6f6e;
}

tr.yellow td {
  background-color: #f9fdbf;
}
tr.yellow-dm td {
  background-color: #555801;
}

.pink {
  color: #fbc8c8;
}
.pink-dm {
  color: #dab1b1;
}

.light-green {
  color: #e2f6c6;
}
.light-green-dm {
  color: #80a54b;
}

.grey {
  color: #c9cbc6;
}
.grey-dm {
  color: #6e6f6e;
}

.yellow {
  color: #f9fdbf;
}
.yellow-dm {
  color: #918020;
}

div.export-note {
  margin-left: 100px;
  margin-right: 10px;
  padding: 4px;
  color: #ffffff;
  background: #000000;
}
div.export-note-dm {
  margin-left: 100px;
  margin-right: 10px;
  padding: 4px;
  color: #ffffff;
  background: #0e0056;
  font-weight: bolder;
}

.icon-button-icon-spacing {
  margin-right: 5px;
}

.rw-permission-search-check {
  width: 100% !important;
  max-width: 100% !important;
}

.rw-close-button {
  background-color: $light;
}

.rw-close-button:hover {
  background-color: #cdcdcd;
  cursor: pointer;
}

.rw-close-button-dm {
  background-color: $dark;
  color: $light;
}

.rw-close-button-dm:hover {
  background-color: #4d4d4d;
  cursor: pointer;
}

.rw-inv-taker-pallet {
  font-size: 10px;
}

tr.rw-inventory-taker-mismatch-scanned td {
  background-color: $accent;
  color: $dark;
}

tr.rw-inventory-taker-mismatch td {
  background-color: $warning;
  color: $dark;
}

tr.rw-inventory-taker-mismatch-verified td {
  background-color: $secondary;
}

tr.rw-inventory-taker-success-scanned td {
  background-color: $success;
  color: $dark;
}

tr.rw-inventory-taker-update-pushed td {
  background-color: yellow;
  color: $dark;
}

.rw-physical-inventory-modal .modal-dialog {
  width: 800px;
  max-width: 95vw !important;
}

.notes-list,
.notes-list-dm {
  overflow: auto;
  max-height: 40vh;
}

.notes-modal .modal-dialog {
  display: flex;
  max-width: 65%;
  max-height: 90%;
}

.shipment-modal .modal-dialog {
  display: flex;
  max-width: 70%;
}

.modal-overlay {
  z-index: 1075;
}

.note-pinned {
  background-color: rgb(252, 252, 179);
  border: 1px solid black;
  border-radius: 10px;
}

.note-pinned-dm {
  background-color: #606060;
  border: 1px solid black;
  border-radius: 10px;
}

.notes-icon-container {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.notes-icon-container.lg {
  width: 2.5em;
  height: 2.5em;
}

.notes-icon-container.sm {
  width: 1.25em;
  height: 1.25em;
}

.notes-count {
  position: absolute;
  color: white;
}

.notes-count.sm {
  font-size: 0.75em;
}

.notes-count.lg {
  font-size: 1em;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-right {
  display: flex;
  align-items: center;
}

.ml-2 {
  margin-left: 0.5rem; /* Adjust the margin as needed */
}

.rw-calendar-container {
  max-width: 1200px;
  margin-left: 0; /* Aligns the container to the left */
  padding: 10px;
  border: 1px solid #ccc; /* Optional: Add a border around the calendar */
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
  background-color: #fff; /* Optional: Set a background color */
}

.rw-calendar-container-dark {
  max-width: 1200px;
  margin-left: 0; /* Aligns the container to the left */
  padding: 10px;
  border: 1px solid #ccc; /* Optional: Add a border around the calendar */
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
  background-color: #060606; /* Optional: Set a background color */
}

.rw-calendar-controls-container {
  max-width: 1200px;
  margin-left: 0; /* Aligns the container to the left */
  padding: 10px;
  border: 1px solid #ccc; /* Optional: Add a border around the calendar */
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
  background-color: #fff; /* Optional: Set a background color */
}

.rw-calendar-controls-container-dark {
  max-width: 1200px;
  margin-left: 0; /* Aligns the container to the left */
  padding: 10px;
  border: 1px solid #ccc; /* Optional: Add a border around the calendar */
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
  background-color: #000000; /* Optional: Set a background color */
}

.rw-calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.rw-day {
  border: 1px solid black;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rw-day-dark {
  border: 1px solid rgb(255, 255, 255);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rw-weekday {
  grid-column: span 1;
  font-weight: bold;
}

.rw-button-group {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  gap: 10px; /* Optional: Adjust the gap between buttons */
}

.rw-time-set-picker {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 5%;
}

.rw-time-set-column {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rw-input-group-gapped {
  display: flex;
  gap: 20px; /* Adds space between the Year and Month inputs */
}

.rw-input-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rw-force-top {
  z-index: 1050 !important;
}

.rw-font-size-sm {
  font-size: $font-size-sm !important;
}

.rw-font-size-base {
  font-size: $font-size-base !important;
}

.rw-fade {
  transition: opacity 0.15s linear;
  opacity: 0;
}

.rw-fade.show {
  opacity: 1;
}

.rw-modal-25 .modal-dialog {
  width: 25vw;
  max-width: 100vw !important;
}

.rw-modal-33 .modal-dialog {
  width: 33vw;
  max-width: 100vw !important;
}

.rw-modal-50 .modal-dialog {
  width: 50vw;
  max-width: 100vw !important;
}

.rw-modal-66 .modal-dialog {
  width: 66vw;
  max-width: 100vw !important;
}

.rw-modal-75 .modal-dialog {
  width: 75vw;
  max-width: 100vw !important;
}

.rw-modal-90 .modal-dialog {
  width: 90vw;
  max-width: 100vw !important;
}

.rw-modal-95 .modal-dialog {
  width: 95vw;
  max-width: 100vw !important;
}

.rw-modal-100 .modal-dialog {
  width: 100vw;
  max-width: 100vw !important;
}

.rw-shipment-card-void {
  background-color: pink !important;
  filter: blur(1.5px);
}

.rw-customer-card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  grid-template-rows: 1fr 1fr; /* Two equal rows */
  gap: 20px; /* Space between the sections */
  padding: 20px;
}

.rw-section {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.rw-section h3 {
  margin-top: 0;
}

/* Responsive layout: Stacks sections into a single column on narrow screens */
@media (max-width: 768px) {
  .rw-customer-card-grid {
    grid-template-columns: 1fr; /* Switch to single column */
    grid-template-rows: auto; /* Automatic row height */
  }
}

.rw-send-email-custom-modal {
  max-width: 100%;
  max-height: 100vh;
  height: 100%;
  min-width: 900px;
  overflow-y: auto;
}

.rw-quote-list-card {
  cursor: pointer;
  background-color: white;
  &:hover {
    background-color: lightgrey;
  }
}

.rw-send-email-custom-modal {
  max-width: 100%;
  max-height: 100vh;
  height: 100%;
  min-width: 900px;
  overflow-y: auto;
}

.rw-send-email-rich-text-editor-container {
  height: 600px; /* Set a fixed height */
  width: 300px; /* Set a fixed height */
  min-height: 600px; /* Ensures it doesn’t shrink below this */
  max-height: 600px; /* Optional: set a max height */
  overflow: auto; /* Ensure scrolling if content exceeds the height */
}

.rw-card-cluster-node-hover:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  transition: all 0.3s ease;
  cursor: pointer;
}

.rw-card-cluster-node-hover-dark:hover {
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  transition: all 0.3s ease;
  cursor: pointer;
}

.rw-card-cluster-node-body-hover:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.rw-card-cluster-node-body-hover:hover {
  background-color: #f8f9fa;
}

.rw-btn-pill {
  border-radius: 50px;
  padding: 0.375rem 0.75rem;
}

.rw-btn-pill-hover:hover {
  background-color: #f8f9fa;
}

.rw-nav-dropdown-row-hover:hover {
  background-color: #f8f9fa;
}

.dropdown-no-caret.dropdown-toggle::after {
  display: none !important;
}

.accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: #85858c !important;
}

.inactive td {
  color: $secondary !important;
}

.rw-sticky-top-div {
  position: sticky;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid #ccc;
}

.rw-no-outline {
  outline: none !important;
  box-shadow: none !important;
}
