@import './color.scss';

$zindex-sticky:                     1000;
$zindex-dropdown:                   1020;
$zindex-fixed:                      1030;
$zindex-modal-backdrop:             1040;
$zindex-offcanvas:                  1050;
$zindex-modal:                      1060;
$zindex-popover:                    1070;
$zindex-tooltip:                    1080;

$body-color: $dark;
$list-group-border-color: transparent;

$offcanvas-horizontal-width: 300px;

$accordion-button-focus-box-shadow: none;

//--- grid row width
.row {
  @media (max-width: 992px) {
    max-width: 100vw !important; // prevents overflow on mobile
  }
}
//===

//--- InputQuantityControl buttons, and all others with "primary" at the moment ---
.btn-primary {
  border-color: $primary;
  background-color: $primary;
  &:hover,
  &:active,
  &:focus {
    @extend .shadow-none;
  }
  &:hover {
    border-color: $primary !important;
    background-color: $primary !important;
  }
  &:active {
    border-color: $secondary !important;
    background-color: $secondary !important;
  }
}
//===

//----- stop shifting content upon navigating and opening offcanvas menu (re: rw-156 ; html, modal-open, fixed-top)
html {
  overflow-y: scroll;
}
.modal-open {
  overflow: auto;
  padding: 0px !important;
}
.fixed-top {
  padding: 0px !important;
}
//============

//--- removing up/down arrows from number input fields ---
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
//---

@import '~bootstrap/scss/bootstrap.scss';

//--- all accordion default color change to primary ---
.accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: $primary !important;
}

.accordion-light .accordion-button:not(.collapsed) {
  color: $dark !important;
  background-color: $light !important;
}

.dm-accordion-light .accordion-button:not(.collapsed) {
  color: $light !important;
  background-color: $dark !important;
}

.dm-accordion-primary .accordion-button:not(.collapsed) {
  color: $light !important;
  background-color: $dm-proline-primary !important;
}

.text-accent {
  color: $accent;
}

.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='dark'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-light.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='dark'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='dark'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.dm-accordion-light.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='dark'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='dark'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.dm-accordion-primary.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='dark'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='dark'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
//---

//---mobile filter buttons
.filter_item_mobile {
  .btn-light {
    --bs-btn-hover-bg: #f1f1f1; //mapped colors not working in this instance, so hardcode rather than const
  }
}
//---

.btn.btn-success:disabled {
  opacity: 0.25 !important
}

.dm-nav-tabs.nav-tabs {
  --bs-nav-link-color: $light;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23a6a6a6%27/></svg>") !important;
}
.form-switch .form-check-input:checked {
  background-color: #24c278 !important;
  border-color: #7ee7b6 !important;
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23fff%27/></svg>") !important;
}

.form-switch .form-check-input:focus {
  border-color: #bfbfbf !important;
  outline: 0;
  box-shadow: none !important;
}

.form-check-input {
  border-color: #d9d9d9 !important;
}
.form-check-input:checked {
  background-color: #24c278 !important;
  border-color: #7ee7b6 !important;
}

.form-check-input:focus {
  border-color: #bfbfbf !important;
}

