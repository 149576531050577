// Spirit-crafted Colors: overriding Bootstrap theme colors, adding custom color names, and mapping colors for global use

$theme-colors: (
  //MONOTONE
  'primary': #282878,
  // Spirit Blue - updated from Sam 8/18
  'secondary': #656567,
  'tertiary': #e6ddff,
  // Single Hue Scale 3 colors, based off Spirit Blue, low intensity, hight brightness. https://www.learnui.design/tools/data-color-picker.html#single
  'accent': #ff0000,
  // Spirit Red - updated from Sam 8/18
  'success': #198754,
  //from Triadic Scale off of Spirit Red; https://www.canva.com/colors/color-wheel/
  'danger': #782828,
  //from Triadic Scale off of Spirit Blue; https://www.canva.com/colors/color-wheel/
  'warning': #ff8000,
  // from Analogous Scale off of Spirit Red; https://www.canva.com/colors/color-wheel/
  'info': #285078,
  // from Analogous Scale off of Spirit Blue; https://www.canva.com/colors/color-wheel/
  'light': #f1f1f1,
  'dark': #2e2e2e,
  'white': #ffffff,
  'dm-proline-primary': #a10d11,
  'dm-body-bg': #212529,
  'selected': #e1e3ed
);

$primary: map_get($theme-colors, 'primary');
$secondary: map_get($theme-colors, 'secondary');
$tertiary: map_get($theme-colors, 'tertiary');

$accent: map_get($theme-colors, 'accent');

$success: map_get($theme-colors, 'success');
$danger: map_get($theme-colors, 'danger');
$warning: map_get($theme-colors, 'warning');
$info: map_get($theme-colors, 'info');

$light: map_get($theme-colors, 'light');
$dark: map_get($theme-colors, 'dark');
$white: map_get($theme-colors, 'white');

$dm-bg: #1e2125;
$dm-body-bg: map_get($theme-colors, 'dm-body-bg');
$dm-card-bg: #333333;
$dm-text: #f1f1f1;
$dm-proline-primary: map_get($theme-colors, 'dm-proline-primary');
// $dm-hover-bg: #4d4d4d;
$dm-hover-bg: #324667;

$excel-green: #1d6f42;
$pdf-red: #f40f02;
